import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  changeEmail,
  changeLogin,
  changePassword,
} from "../features/LoginSlice.jsx";
import css from "../styles/Header.module.scss";

export default function Header(props) {
  const dispatch = useDispatch();
  const isLogin = useSelector((s) => s.LoginSlice.isLogin);

  function removeAccount() {
    localStorage.clear();
    dispatch(changeLogin(false));
    dispatch(changeEmail(`admin@admin.com`));
    dispatch(changePassword(`admin@admin.com`));
  }
  return (
    <nav className={css.navbar}>
      <NavLink to="/" pathname="/" className="ml-2 rounded-md">
        <p className="py-2 px-3 rounded-md bg-gray-200">Trang chủ</p>
      </NavLink>
      {isLogin ? (
        <>
          <button
            className="py-2 px-3 rounded-md bg-gray-200 ml-3"
            onClick={() => dispatch(changeLogin(false))}
          >
            Logout
          </button>
          <button
            className="py-2 px-3 rounded-md bg-gray-200 ml-3"
            onClick={removeAccount}
          >
            Xóa tài khoản
          </button>
        </>
      ) : null}
    </nav>
  );
}
