import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";
import { RiMenuFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { changeDislike, changeLike } from "../../features/MusicSlice.jsx";

export default function EmojiPanel() {
  // console.log("EmojiPanel");
  const { songs, nowIndex } = useSelector((s) => s.MusicSlice);
  const dispatch = useDispatch();

  function like() {
    // Đang dislike thì like thành true, dislike thành false
    if (!songs[nowIndex].like && songs[nowIndex].dislike) {
      dispatch(changeDislike({ id: nowIndex, value: false }));
      dispatch(changeLike({ id: nowIndex, value: true }));
      return;
    }
    // Chưa like thì like
    if (!songs[nowIndex].like) {
      dispatch(changeLike({ id: nowIndex, value: true }));
    }
    // đang like thì bỏ like
    if (songs[nowIndex].like) {
      return dispatch(changeLike({ id: nowIndex, value: false }));
    }
  }
  function dislike() {
    // Đang like thì dislike thành true, like thành false
    if (songs[nowIndex].like && !songs[nowIndex].dislike) {
      dispatch(changeDislike({ id: nowIndex, value: true }));
      dispatch(changeLike({ id: nowIndex, value: false }));
    }
    // Chưa dislike thì dislike
    if (!songs[nowIndex].dislike) {
      return dispatch(changeDislike({ id: nowIndex, value: true }));
    }
    // Đang dislike thì bỏ dislike
    if (songs[nowIndex].dislike) {
      return dispatch(changeDislike({ id: nowIndex, value: false }));
    }
  }
  return (
    <>
      <>
        <div className="emoji h-10 text-lg mt-8 flex items-center">
          <button className="thump-up ml-3" onClick={like}>
            {songs[nowIndex].like ? <HiThumbUp /> : <HiOutlineThumbUp />}
          </button>
          <button className="thump-down ml-3" onClick={dislike}>
            {songs[nowIndex].dislike ? <HiThumbDown /> : <HiOutlineThumbDown />}
          </button>
          <div className=" ml-auto mr-3 relative pl-3 py-3 cursor-pointer">
            <RiMenuFill />
          </div>
        </div>
      </>
    </>
  );
}
