import { useDispatch, useSelector } from "react-redux";
import { toggleShowRulePanel } from "../../features/DiceSlice.jsx";

export default function RulePanel(props) {
  const showRulePanel = useSelector((state) => state.DiceSlice.showRulePanel);
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={`fixed top-0 bottom-0 left-0 right-0 z-10 bg-gray-900 bg-opacity-70 ${
          showRulePanel ? "flex" : "hidden"
        }`}
      >
        <div className="w-96 h-96 p-5 bg-gray-100 rounded-md relative m-auto">
          <p className="font-semibold text-2xl text-center">Luật chơi</p>
          <ol className="list-decimal p-3">
            <li>Người chơi có tổng điểm nhiều hơn 100 là người chiến thắng</li>
            <li>Lượt đầu tiên bắt đầu từ người chơi 1</li>
            <li>
              Nếu 1 trong 2 con xúc xắc quay vào mặt 1 chấm, điểm hiện tại của
              người đó trở về 0 và lượt chơi chuyển sang người còn lại
            </li>
            <li>
              Người chơi có thể ấn chuyển lượt để kết thúc lượt chơi của mình,
              điểm hiện tại đã quay được trong lượt vừa rồi sẽ được cộng dồn vào
              tổng điểm của người chơi
            </li>
          </ol>
          <div className="flex">
            <button
              className="bg-gray-200 rounded-md py-2 px-4 uppercase mx-auto hover:bg-gray-300"
              onClick={() => dispatch(toggleShowRulePanel(false))}
            >
              Đã hiểu luật chơi
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
