import DiceController from "../components/Dice/DiceController";
import DicePanel from "../components/Dice/DicePanel";
import PlayerPanel from "../components/Dice/PlayerPanel";
import RulePanel from "../components/Dice/RulePanel";
import "../styles/Dice.css";

export default function Dice() {
  return (
    <>
      <div className="dice-body w-9/12 shadow-2xl">
        <div className="container">
          <DiceController />
          <PlayerPanel />
          <DicePanel />
        </div>
      </div>
      <RulePanel />
    </>
  );
}
