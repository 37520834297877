import { configureStore } from "@reduxjs/toolkit";
import calResult from "../features/CalculatorSlice.jsx";
import DiceSlice from "../features/DiceSlice.jsx";
import MusicSlice from "../features/MusicSlice.jsx";
import LoginSlice from "../features/LoginSlice.jsx";
const rootReducer = {
  calResult,
  DiceSlice,
  MusicSlice,
  LoginSlice,
};

export const store = configureStore({
  reducer: rootReducer,
});
