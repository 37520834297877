import { useSelector } from "react-redux";
// import background from "../../img/MusicPlayer/avatar.jpg";

export default function SongCover() {
  //   console.log("SongCover");
  const { songs, nowIndex, isPlaying } = useSelector((s) => s.MusicSlice);
  return (
    <div className="music-cover h-80 p-4 w-full flex">
      <div
        className="music-cover__image rounded-full h-80 w-80 relative m-auto"
        style={{
          backgroundImage: `url(${songs[nowIndex].img})`,
          // animation: `${
          //   isPlaying
          //     ? "rotate-image 10s linear infinite running"
          //     : "rotate-image 10s linear infinite paused"
          // }`,
          boxShadow: `${isPlaying ? "0 0 40px 10px rgb(128, 0, 122)" : ""}`,
        }}
      >
        {/* <div className="music-cover__circle absolute"></div>
        <div className="music-cover__circle2 absolute"></div> */}
      </div>
    </div>
  );
}
