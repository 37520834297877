import { Howl } from "howler";
import { useEffect, useRef, useState } from "react";
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiPauseCircleLine,
  RiPlayFill,
  RiRewindFill,
  RiSpeedFill,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDuration,
  changeNowIndex,
  toggleIsPlaying,
} from "../../features/MusicSlice.jsx";

const arrTimeout = [];

export default function Controller() {
  const dispatch = useDispatch();
  const { songs, nowIndex, isPlaying, isLoop, isShuffer, duration, timer } =
    useSelector((s) => s.MusicSlice);
  const [isMenu, setIsMenu] = useState(false);

  const [howl, setHowl] = useState(() => [
    new Howl({
      src: [songs[nowIndex].audio],
      html5: true,
      onplay: () => {
        refTimeLine.current.innerText = formatTime(howl[nowIndex].seek());
      },
    }),
  ]);

  const refTimeLine = useRef();
  const refTimeButton = useRef();

  // Khi bài đầu tiên load thì đẩy các bài còn lại vào mảng howl
  useEffect(() => {
    if (howl.length === 1) {
      howl[0].on("load", () => {
        songs.forEach((element, index) => {
          if (nowIndex !== index) {
            howl.push(
              new Howl({
                src: [element.audio],
                html5: true,
              })
            );
          }
        });
      });
    }
  }, []);

  // Neu timer thay doi thi setTimeOut tuong ung
  useEffect(() => {
    switch (timer) {
      case 0:
        arrTimeout.length = 0;
        break;
      case 10:
        createTimeout(10);
        break;
      case 20:
        createTimeout(20);
        break;
      case 30:
        createTimeout(30);
        break;
      case 60:
        createTimeout(60);
        break;
    }
    return () => {
      arrTimeout[0] && clearTimeout(arrTimeout[0]);
      arrTimeout.pop();
    };
  }, [timer]);

  // Cap nhat timeLine lien tuc
  useEffect(() => {
    const timer = setInterval(() => {
      if (isPlaying) {
        const duration = howl[nowIndex].duration();
        const seek = howl[nowIndex].seek();
        refTimeLine.current.innerText = formatTime(seek);
        refTimeButton.current.value = (seek * 10000) / duration;
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [nowIndex, isPlaying]);

  // Cứ 500ms thì check xem đã nạp hết song vào howl chưa, nạp đủ thì gửi duration lên store rồi clear, chưa nạp đủ thì chạy đến khi nạp đủ thì thôi
  useEffect(() => {
    const durationArray = [];
    const loop = setInterval(() => {
      if (howl.length === songs.length) {
        howl.forEach((element) => {
          durationArray.push(formatTime(element.duration()));
        });
        dispatch(changeDuration(durationArray));
        clearInterval(loop);
      }
    }, 500);
  }, []);

  // khi end thi setup bai ke tiep theo trang thai cua isLoop
  useEffect(() => {
    if (isLoop === "playOnce") {
      howl[nowIndex].off("end");
      howl[nowIndex].on("end", () => {
        stop();
      });
    }
    if (isLoop === "loopOnce") {
      howl[nowIndex].off("end");
      howl[nowIndex].loop(true);
    }
    if (isLoop === "playNext") {
      if (isShuffer) {
        for (const song of howl) {
          song.off("end");
          song.once("end", () => {
            stop();
            play(random());
          });
        }
      } else {
        for (const song of howl) {
          song.off("end");
          song.once("end", () => {
            if (nowIndex === songs.length - 1) {
              stop();
              play(0);
            } else {
              stop();
              play(nowIndex + 1);
            }
          });
        }
      }
    }
  }, [isLoop, isShuffer, nowIndex]);

  function createTimeout(minutes) {
    arrTimeout.push(
      setTimeout(() => {
        pause();
      }, minutes * 60000)
    );
  }
  function formatTime(secs) {
    const minutes = Math.floor(secs / 60) || 0;
    const seconds = Math.floor(secs - minutes * 60) || 0;
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  }

  function play(index = nowIndex) {
    let sound = howl[index];

    // Nếu không truyền index \\ index = bài đang chơi thì chơi bài hiện tại
    if (index === undefined || index === nowIndex) {
      // console.info(`Controller-choiBaiHienTai-${Math.random()}`);
      sound.play();
      dispatch(toggleIsPlaying(true));
      return;
    }

    index = typeof index === `number` ? index : nowIndex;
    // Nếu có bài trong howl thì chạy bài đó luôn
    if (sound) {
      sound.play();
    }
    // Nếu chưa có bài trong howl thì gán bài đó với index tương ứng
    else {
      sound = new Howl({
        src: [songs[index].audio],
        html5: true,
      });
      // Lưu bài sẽ phát vào howl rồi phát
      const newHowl = howl.slice();
      newHowl[index] = sound;
      setHowl(newHowl);
      sound.play();
    }
    // Update bài đang chạy
    dispatch(toggleIsPlaying(true));
    dispatch(changeNowIndex(index));
  }

  function stop() {
    howl[nowIndex].stop();
    dispatch(toggleIsPlaying(false));
  }

  function pause() {
    howl[nowIndex].pause();
    dispatch(toggleIsPlaying(false));
  }

  function random(id = nowIndex) {
    while (true) {
      const random = Math.floor(Math.random() * howl.length);
      if (random !== id) {
        return random;
      }
    }
  }

  function nextSong() {
    stop();
    // chưa chạy thì chỉ next, không chạy
    if (!isPlaying) {
      // Doi bai ngau nhien
      if (isShuffer) {
        dispatch(changeNowIndex(Math.floor(Math.random() * songs.length)));
        refTimeButton.current.value = 0;
      } else {
        // Neu next den bai cuoi thi set thanh bai dau tien
        if (nowIndex === songs.length - 1) {
          dispatch(changeNowIndex(0));
          refTimeButton.current.value = 0;
        }
        // Khong phai bai cuoi thi index tang len 1
        else {
          dispatch(changeNowIndex(nowIndex + 1));
          refTimeButton.current.value = 0;
        }
      }
    }
    // đang chạy thì stop cai dang chay và play cái mới
    else {
      if (isShuffer) {
        const randomId = random();
        dispatch(changeNowIndex(randomId));
        play(randomId);
      } else {
        if (nowIndex === songs.length - 1) {
          dispatch(changeNowIndex(0));
          play(0);
        } else {
          dispatch(changeNowIndex(nowIndex + 1));
          play(nowIndex + 1);
        }
      }
    }
  }

  function preSong() {
    stop();
    // Dang pause thi chi lui, ko play
    if (!isPlaying) {
      // Neu dang o bai dau thi set thanh bai cuoi
      if (nowIndex === 0) {
        dispatch(changeNowIndex(songs.length - 1));
      }
      // Chua den bai dau thi lui ve 1
      else {
        dispatch(changeNowIndex(nowIndex - 1));
      }
    }
    // Dang chay thi stop, lui ve va play cai moi
    else {
      if (nowIndex === 0) {
        dispatch(changeNowIndex(songs.length - 1));
        play(songs.length - 1);
      } else {
        dispatch(changeNowIndex(nowIndex - 1));
        play(nowIndex - 1);
      }
    }
  }

  function addSeek() {
    // Đang play thì nhảy tới vị trí theo % truyền vào
    if (isPlaying) {
      howl[nowIndex].seek(howl[nowIndex].seek() + 10);
    } else {
      console.log(`khong lam gi ca`);
    }
  }

  function substractSeek() {
    howl[nowIndex].seek(0);
    refTimeButton.current.value = 0;
  }

  function chooseFromList(index) {
    setIsMenu(false);
    stop();
    play(index);
  }

  function seekOnTimeLine(e) {
    howl[nowIndex].seek((e.target.value / 10000) * howl[nowIndex].duration());
  }

  return (
    <>
      <div className="controller relative h-28 px-3 flex flex-col">
        {/* Playlist */}
        <div
          className="more-option w-10 h-10 bg-transparent absolute cursor-pointer"
          onClick={() => setIsMenu(true)}
        ></div>

        <div
          className={`background absolute left-0 right-0 ${
            isMenu ? "flex" : "hidden"
          }`}
          onClick={() => setIsMenu(false)}
        >
          <div
            className={`justify-center relative  list-panel-songs rounded-xl m-auto`}
          >
            <div className="h-96 w-72 bg-gray-800 rounded-xl">
              <ul className="h-full p-4">
                {songs.map((element, index) => {
                  return (
                    <li
                      className="h-1/6 list-songs-popup mb-1 style"
                      key={index}
                      onClick={() => chooseFromList(index)}
                    >
                      <p className="song-popup-title">{`${index + 1}. ${
                        element.title
                      }`}</p>
                      <p className="song-popup-singer text-xs ml-4">
                        {element.singer}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {/* Prev, Play/Pause, Next */}
        <div className="remote-controller flex h-full">
          <div className="m-auto flex items-center">
            <button
              className="mx-2 text-2xl rounded-lg"
              onClick={substractSeek}
            >
              <RiArrowGoBackLine />
            </button>
            <button className="mx-2 text-2xl" onClick={preSong}>
              <RiRewindFill />
            </button>
            <button className="mx-2 text-7xl">
              {isPlaying ? (
                <RiPauseCircleLine onClick={pause} />
              ) : (
                <RiPlayFill onClick={() => play()} />
              )}
            </button>
            <button className="mx-2 text-2xl" onClick={nextSong}>
              <RiSpeedFill />
            </button>
            <button className="mx-2 text-2xl" onClick={() => addSeek()}>
              <RiArrowGoForwardLine />
            </button>
          </div>
        </div>
      </div>
      {/* TimeLine */}
      <div className="timeline h-16 px-3">
        <input
          type="range"
          name="timeLine"
          id="timeLine"
          className="h-1 w-full mt-4 cursor-pointer"
          min="0"
          max="10000"
          ref={refTimeButton}
          onInput={(e) => {
            seekOnTimeLine(e);
          }}
        />
        <div className="flex justify-between text-xs font-bold mt-2">
          <div className="timeNow" ref={refTimeLine}>
            00:00
          </div>
          <div className="duration">
            {!duration ? null : duration[nowIndex]}
          </div>
        </div>
      </div>
    </>
  );
}
