import { Link } from "react-router-dom";

export default function DashBoard() {
  return (
    <>
      <div className="w-2/3 h-36 grid grid-cols-3 gap-4 text-3xl text-white ">
        <Link
          to="/may-tinh"
          className="link-dashboard from-green-400 to-blue-500 "
        >
          <div className="m-auto ">App máy tính</div>
        </Link>
        <Link
          to="/xuc-xac"
          className="link-dashboard from-purple-400 via-pink-500 to-red-500"
        >
          <div className="m-auto">Game xúc xắc</div>
        </Link>
        <Link
          to="/music-player"
          className="link-dashboard from-green-600 to-red-300"
        >
          <div className="m-auto">Music Player</div>
        </Link>
      </div>
    </>
  );
}
