export default function ManorFeatures(props) {
  return (
    <>
      <div className="music-player-function bg-gray-200 mr-16 ml-6 rounded-md px-4 py-2">
        <h3 className="font-bold text-2xl">Các chức năng chính:</h3>
        <ol className="list-decimal ml-4">
          <li>
            Play/pause: Mặc định tự động chuyển bài kế tiếp khi kết thúc, lặp vô
            tận
          </li>
          <li>
            NextSong/PrevSong:
            <ul className="list-disc">
              <li>
                Khi chưa play, nếu next/prev thì chuyển bài nhưng không
                autoplay, khi nào ấn play mới chơi
              </li>
              <li>
                Khi đang play, ấn next/prev sẽ chuyển bài + autoplay bài mới
              </li>
              <li>Khi đến bài cuối, ấn next sẽ quay lại bài đầu tiên</li>
              <li>
                Khi đang ở bài đầu tiên, ấn prev sẽ đưa đến bài cuối trong list
              </li>
            </ul>
          </li>
          <li>Chức năng nhảy thời gian: Tiến lên 10s và lùi về lúc mới phát</li>
          <li>Chức năng like và dislike, mặc định là không có trạng thái</li>
          <li>
            Chức năng mở rộng danh sách, hiện tất cả các bài hát trong list
            nhạc, khi click vào bài nào thì play bài đó
          </li>
          <li>
            Chức năng điều chỉnh âm lượng (dạng kéo + click), lưu lại trạng thái
            cho bài kế tiếp
          </li>
          <li>Chức năng hẹn giờ tắt nhạc với 4 tùy chọn (10/20/30/60 phút)</li>
          <li>
            Chức năng tùy chọn phát nhạc với 3 lựa chọn:
            <ul className="list-disc">
              <li>
                Mặc định: Khi hết bài sẽ autoplay bài kế tiếp, lặp lại không
                ngừng
              </li>
              <li>Lặp lại bài hiện tại không ngừng</li>
              <li>Khi hết bài hiện tại sẽ dừng</li>
            </ul>
          </li>
          <li>
            Chức năng play ngẫu nhiên không theo thứ tự, chuyển sang bài kế tiếp
            (không giống bài đang play)
          </li>
          <li>Thanh timeLine hiển thị quá trình phát nhạc</li>
          <li>Có thể tua nhạc bằng cách kéo nút/click trên timeLine</li>
          <li>
            Hiển thị thời gian hiện tại và tổng thời gian bài nhạc đang phát
          </li>
          <li>Hạn chế tối đa render không cần thiết</li>
        </ol>
      </div>
    </>
  );
}
