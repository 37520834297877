import { Howler } from "howler";
import { useEffect } from "react";
import Controller from "../components/MusicPlayer/Controller";
import EmojiPanel from "../components/MusicPlayer/EmojiPanel";
import FunctionPanel from "../components/MusicPlayer/FunctionPanel";
import ManorFeatures from "../components/MusicPlayer/ManorFeatures";
import SongCover from "../components/MusicPlayer/SongCover";
import SongTittle from "../components/MusicPlayer/SongTittle";
import "../styles/MusicPlayer.scss";

export default function MusicPlayer() {
  useEffect(() => {
    return () => {
      Howler.stop();
    };
  });
  return (
    <div className="flex">
      <ManorFeatures />
      <div className="bg-black w-96 min-h flex flex-col text-white overflow-hidden">
        <SongCover />
        <EmojiPanel />
        <SongTittle />
        <FunctionPanel />
        <Controller />
      </div>
    </div>
  );
}
