import React from "react";
import ButtonArea from "../components/Calculator/ButtonArea.jsx";
import ResultHistoryArea from "../components/Calculator/ResultHistoryArea.jsx";
import ResultRecentArea from "../components/Calculator/ResultRecentArea.jsx";

export default function Calculator() {
  return (
    <div className="flex ">
      <div className="mr-10 max-w-md border rounded-xl bg-gray-100 p-7">
        <h3 className="font-bold text-center text-3xl">Các chức năng chính</h3>
        <ol className="list-decimal p-4 text-lg">
          <li>Tính toán dựa trên biểu thức nhập vào màn hình</li>
          <li>
            Các biểu thức không tính được do nhập sai sẽ hiển thị thông báo lỗi
            và reset để điền lại
          </li>
          <li>Lưu lại lịch sử 3 phép tính gần nhất</li>
          <li>
            Khi click vào lịch sử, tự động gán lại kết quả vào biểu thức tính
            toán
          </li>
          <li>
            Khi tính xong, nếu tiếp tục click thì sẽ điền tiếp vào kết quả trước
            đó
          </li>
        </ol>
      </div>
      <div className="bg-black w-96 shadow-xl flex flex-col">
        <div className="result flex flex-col h-80">
          <ResultHistoryArea />
          <ResultRecentArea />
        </div>
        <div className="border-b-1 w-4/5 m-auto"></div>
        <ButtonArea />
      </div>
    </div>
  );
}
