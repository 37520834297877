import { createSlice } from "@reduxjs/toolkit";

const email = localStorage.getItem("email");
const password = localStorage.getItem("password");
const isLogin = localStorage.getItem("login");

const initialState = {
  email: email ? email.toString() : "admin@admin.com",
  password: password ? password.toString() : "admin@admin.com",
  isLogin: isLogin ? JSON.parse(isLogin) : false,
};

const LoginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    changeLogin: (s, action) => {
      s.isLogin = action.payload;
    },
    changeEmail: (s, action) => {
      s.email = action.payload;
    },
    changePassword: (s, action) => {
      s.password = action.payload;
    },
  },
});

export const { changeLogin, changeEmail, changePassword } = LoginSlice.actions;
export default LoginSlice.reducer;
