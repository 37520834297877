import { useSelector } from "react-redux";

export default function ResultRecentArea() {
  const { result, expression } = useSelector((s) => s.calResult);
  return (
    <div className="resultArea h-1/4 text-white mr-4 flex flex-col">
      <p className="ml-auto text-2xl">{`${expression}`}</p>
      <p className="ml-auto text-3xl text-gray-300">
        {result ? `=${result}` : null}
      </p>
    </div>
  );
}
