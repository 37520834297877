import { createSlice } from "@reduxjs/toolkit";
import song0 from "../audio/Họ Yêu Ai Mất Rồi (Orinn Remix) - Doãn Hiếu  Nhạc Trẻ Edm Hot Tik Tok Gây Nghiện Hay Nhất 2021.mp3";
import song1 from "../audio/[Vietsub] Criminal - Britney Spears.mp3";
import song2 from "../audio/If I Had You.m4a";
import song3 from "../audio/Lady Gaga - Bad Romance (Official Music Video).mp3";
import song4 from "../audio/Star Sky.mp3";
import song5 from "../audio/Pitbull - Feel This Moment ft. Christina Aguilera.mp3";

const initialState = {
  songs: [
    {
      title: `Họ Yêu Ai Mất Rồi (Orinn Remix)`,
      singer: `Doãn Hiếu`,
      img: "./image/avatar.jpg",
      audio: song0,
      like: false,
      dislike: false,
      duration: null,
    },
    {
      title: `Criminal`,
      singer: `Britney Spears`,
      img: "./image/148e3f90-f3e5-4abd-8245-2df5f4851917_1024.jpg",
      audio: song1,
      like: false,
      dislike: false,
      duration: null,
    },
    {
      title: `If I Had You`,
      singer: `Adam Lambert`,
      img: "./image/R-5841220-1404182435-5049.jpeg.jpg",
      audio: song2,
      like: false,
      dislike: false,
      duration: null,
    },
    {
      title: `Bad Romance`,
      singer: `Lady Gaga`,
      img: "./image/badromance.jpg",
      audio: song3,
      like: false,
      dislike: false,
      duration: null,
    },
    {
      title: `Star Sky`,
      singer: `Two Step From Hell`,
      img: "./image/starSky.jpg",
      audio: song4,
      like: false,
      dislike: false,
      duration: null,
    },
    {
      title: `Feel This Moment`,
      singer: `Pitbull ft. Christina Aguilera`,
      img: "./image/feelThisMoment.jpg",
      audio: song5,
      like: false,
      dislike: false,
      duration: null,
    },
  ],
  nowIndex: 0,
  isPlaying: false,
  timeStamp: null,
  duration: null,
  // isLoop nhận 3 trạng thái: playNext, playOnce, loopOnce
  isLoop: "playNext",
  isShuffer: false,
  // timmer nhan cac trang thai 0, 10, 20, 30, 60
  timer: 0,
};

const MusicSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    changeNowIndex: (s, action) => {
      s.nowIndex = action.payload;
    },
    toggleIsPlaying: (s, action) => {
      s.isPlaying = action.payload;
    },
    changeLike: (s, action) => {
      s.songs[action.payload.id].like = action.payload.value;
    },
    changeDislike: (s, action) => {
      s.songs[action.payload.id].dislike = action.payload.value;
    },
    handleTimeStamp: (s, action) => {
      s.timeStamp = action.payload;
    },
    changeDuration: (s, action) => {
      s.duration = action.payload;
    },
    changeIsLoop: (s, action = "playNext") => {
      s.isLoop = action.payload;
    },
    toggleShuffer: (s) => {
      s.isShuffer = !s.isShuffer;
    },
    changeTimer: (s, action) => {
      s.timer = action.payload;
    },
  },
});

export const {
  changeNowIndex,
  changeLike,
  changeDislike,
  toggleIsPlaying,
  handleTimeStamp,
  changeDuration,
  changeIsLoop,
  toggleShuffer,
  changeTimer,
} = MusicSlice.actions;
export default MusicSlice.reducer;
