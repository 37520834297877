import { createSlice } from "@reduxjs/toolkit";

const DiceSlice = createSlice({
  name: `dice`,
  initialState: {
    activePlayer: 1,
    playerScore: {
      1: 0,
      2: 0,
    },
    currentScore: {
      1: 0,
      2: 0,
    },
    dice1: 3,
    dice2: 4,
    isWinner: null,
    showRulePanel: true,
  },
  reducers: {
    resetScore: function (s) {
      s.playerScore[1] = 0;
      s.playerScore[2] = 0;
      s.currentScore[1] = 0;
      s.currentScore[2] = 0;
      s.activePlayer = 1;
      s.isWinner = null;
    },
    rollDice: function (s) {
      s.dice1 = Math.floor(Math.random() * 6) + 1;
      s.dice2 = Math.floor(Math.random() * 6) + 1;
    },
    addCurrentScore: function (s) {
      s.currentScore[s.activePlayer] =
        s.currentScore[s.activePlayer] + s.dice1 + s.dice2;
    },
    addPlayerScore: function (s) {
      s.playerScore[s.activePlayer] =
        s.playerScore[s.activePlayer] + s.currentScore[s.activePlayer];
    },
    resetCurrenScore: function (s) {
      s.currentScore[1] = 0;
      s.currentScore[2] = 0;
    },
    swapPlayer: function (s, action) {
      s.activePlayer = action.payload;
    },
    toggleShowRulePanel: function (s, action) {
      s.showRulePanel = action.payload;
    },
    setPlayerWinner: function (s, action) {
      s.isWinner = action.payload;
    },
  },
});

export const {
  resetScore,
  rollDice,
  swapPlayer,
  resetCurrenScore,
  addPlayerScore,
  addCurrentScore,
  toggleShowRulePanel,
  setPlayerWinner,
} = DiceSlice.actions;
export default DiceSlice.reducer;
