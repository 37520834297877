import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { changeLogin, changeEmail, changePassword } from '../features/LoginSlice.jsx';
import css from '../styles/Login.module.scss';

const InputCheckbox = styled.div`
	width: 100%;
	height: 34px;
	margin: 6px 0;
	position: relative;

	#chk {
		display: none;
	}
	.text {
		height: 100%;
		position: absolute;
		font-size: 12px;
		line-height: 16px;
		text-indent: 20px;
		overflow: hidden;
		user-select: none;

		&:hover {
			cursor: pointer;
		}
	}

	.checkbox {
		width: 10px;
		height: 10px;
		position: absolute;
		left: 6px;
		top: 8px;
		color: #555;
		border: 2px solid #555;
		transition: all ease-out 150ms;
		transform: translate(-50%, -50%);
		border-radius: 100%;

		&:hover {
			cursor: pointer;
		}

		&:after {
			content: '';
			width: 10px;
			height: 4px;
			position: absolute;
			left: 50%;
			top: 25%;
			transition: all ease-out 150ms;
			opacity: 0;
			transform: rotate(-45deg);
			transform-origin: bottom left;
			border-bottom: 2px solid #3366ff;
			border-left: 2px solid #3366ff;
		}
	}

	input[type='checkbox']:checked + label {
		border: 2px solid #3366ff;

		&:after {
			width: 10px;
			opacity: 1;
		}
	}
`;
export default function Login() {
	const { email, password } = useSelector(s => s.LoginSlice);
	const dispatch = useDispatch();
	const formRegister = useRef();
	const formLogin = useRef();
	const isChecked = useRef(false);
	const loginButton = useRef();

	function changeCheckbox() {
		isChecked.current = !isChecked.current;
	}

	function Validator(formSelector) {
		let isInvalid = {};
		const dataSubmit = {};
		const inputs = formSelector.querySelectorAll('input[data-rules]');

		const validatorRules = {
			required: function (value) {
				return value ? null : 'Vui lòng nhập trường này';
			},
			isEmail: function (value) {
				const re =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(value).toLowerCase()) ? null : 'Cần nhập đúng định dạng email';
			},
			min: function (value, min) {
				return value.length >= min ? null : `Cần nhập ít nhất ${min} ký tự`;
			},
			max: function (value, max) {
				return value.length <= max ? null : `Chỉ được nhập tối đa ${max} ký tự`;
			},
			isConfirmed: function (value, checked) {
				let isCompare = formSelector.querySelector(`#${checked}`).value === value;
				return isCompare ? null : `Cần nhập vào giá trị giống với ${checked}`;
			},
		};

		function showErrorMessage(input) {
			const errorElement = formSelector.querySelector(`.error-message-${input.id}`);
			let errorMessage;
			const inputRules = input.dataset.rules;
			const arrayRules = inputRules.split('|');
			for (let rule of arrayRules) {
				const separate = rule.indexOf(':');
				if (separate === -1 && rule) {
					errorMessage = validatorRules[rule](input.value);
					if (errorMessage) {
						errorElement.innerText = errorMessage;
						input.classList.add(css.invalid);
						return false;
					}
				} else {
					let ruleName = rule.slice(0, separate);
					let ruleValue = rule.slice(separate + 1);
					errorMessage = validatorRules?.[ruleName](input.value, ruleValue);
					if (errorMessage) {
						errorElement.innerText = errorMessage;
						input.classList.add(css.invalid);
						return false;
					}
				}
			}
			if (!errorMessage) {
				dataSubmit[input.id] = input.value;
				return true;
			}
		}

		for (let input of inputs) {
			const errorElement = formSelector.querySelector(`.error-message-${input.id}`);
			isInvalid[input.id] = false;
			input.onblur = function () {
				isInvalid[input.id] = showErrorMessage(input);
			};
			input.onfocus = function () {
				errorElement.innerText = null;
				input.classList.remove(css.invalid);
			};
		}

		// tạo 1 mảng trạng thái lỗi của các ô input rồi duyệt qua từng cái, cái nào lỗi hiển thị error message
		function checkOnsubmit() {
			const arrayErrorValue = Object.getOwnPropertyNames(isInvalid);
			for (let error of arrayErrorValue) {
				if (!isInvalid[error]) {
					const currentErrorInput = formSelector.querySelector(`input[id=${error}]`);
					showErrorMessage(currentErrorInput);
					return;
				}
			}
		}
		const buttonSubmit = formSelector.querySelector('button[type="submit"]');
		if (formSelector.id === 'register-form') {
			buttonSubmit.onclick = e => {
				e.preventDefault();
				checkOnsubmit();
				// Call API tai day
				dataSubmit.checked = isChecked.current;
				console.log(dataSubmit);
				for (const key in dataSubmit) {
					localStorage.setItem(key, dataSubmit[key]);
				}
				dispatch(changeEmail(dataSubmit[`email`]));
				dispatch(changePassword(dataSubmit['password']));
				formSelector.reset();
			};
		}

		if (formSelector.id === 'login-form') {
			buttonSubmit.onclick = e => {
				e.preventDefault();
				// Check trên store xem có chuẩn không, nếu chuẩn thì render dashboard
				if (dataSubmit['email-login'] === email && dataSubmit['password-login'] === password) {
					localStorage.setItem('login', 'true');
					dispatch(changeLogin(true));
				} else {
					checkOnsubmit();
					console.log(`Đăng nhập thất bại`);
				}
			};
		}
	}
	useEffect(() => {
		Validator(formRegister.current);
		Validator(formLogin.current);
	}, [email, password]);

	return (
		<div className='flex'>
			<div className='flex flex-col h-auto w-80 border mr-10 rounded p-5 bg-gray-50 leading-relaxed'>
				<p className='text-xl font-semibold text-center mb-1'>Form login/register build bằng thư viện tự viết</p>
				<p>Ưu điểm so với dùng thư viện ngoài như Formik hoặc React Hook Form</p>
				<ul className='list-disc ml-6'>
					<li>Code ít và dễ dàng tái sử dụng</li>
					<li>Hoàn không cần re-render mà vẫn đảm bảo validate chuẩn xác</li>
					<li>Thêm các rule validate dễ dàng</li>
				</ul>
				<p>Khi submit form register in ra log dưới dạng object</p>
				<p>- Có thể đăng ký rồi đăng nhập thử, thông tin được lưu vào localStorage</p>
				<p>- Hoặc có thể đăng nhập luôn bằng tài khoản mặc định với email + password giống nhau: admin@admin.com</p>
			</div>
			<form className={`${css.pane} mr-10`} ref={formRegister} autoComplete='off' id='register-form'>
				<h5>Đăng ký thành viên</h5>
				<div className={css.formGroup}>
					<label htmlFor='fullname'>Tên đăng nhập *</label>
					<input type='text' className='fullname' placeholder='VD: truong1993' id='fullname' data-rules='required' />
					<div className='error-message-fullname'></div>
				</div>
				<div className={css.formGroup}>
					<label htmlFor='email'>Email *</label>
					<input type='text' className='email' placeholder='VD: truong@gmail.com' id='email' data-rules='required|isEmail|min:6' />
					<div className='error-message-email'></div>
				</div>
				<div className={css.formGroup}>
					<label htmlFor='password'>Mật khẩu *</label>
					<input type='password' className='password' placeholder='Tối thiểu 8 ký tự' id='password' data-rules='required|min:8' />
					<div className='error-message-password'></div>
				</div>
				<div className={css.formGroup}>
					<label htmlFor='password-comfirm'>Nhập lại mật khẩu *</label>
					<input type='password' className='password-comfirm' id='password-comfirm' data-rules='required|isConfirmed:password' />
					<div className='error-message-password-comfirm'></div>
				</div>
				<InputCheckbox onChange={changeCheckbox}>
					<input type='checkbox' id='chk' name='acceptEmailAds' value={isChecked.current} />
					<label htmlFor='chk' className='checkbox'></label>
					<label htmlFor='chk' className='text'>
						Đăng ký nhận thông tin khuyến mại và tin tức mới nhất qua email
					</label>
				</InputCheckbox>
				<button type='submit' className='w-full bg-blue-600 text-white rounded-sm py-1' ref={loginButton}>
					ĐỒNG Ý VÀ ĐĂNG KÝ
				</button>
				<p className={css.policy}>
					Bằng cách nhấn vào đăng ký thành viên, bạn đã đồng ý với <a href='#'>điều khoản của chúng tôi</a>
				</p>
			</form>

			<form className={`${css.pane} my-auto`} ref={formLogin} autoComplete='off' id='login-form'>
				<h5>Đăng Nhập</h5>
				<div className={css.formGroup}>
					<label htmlFor='email'>Email đăng nhập*</label>
					<input type='text' className='email' placeholder='VD: admin@admin.com' id='email-login' data-rules='isEmail|min:6' />
					<div className='error-message-email-login'></div>
				</div>
				<div className={css.formGroup}>
					<label htmlFor='password'>Mật khẩu *</label>
					<input type='password' className='password' placeholder='Mật khẩu tối thiểu 8 ký tự' id='password-login' data-rules='required|min:8' />
					<div className='error-message-password-login'></div>
				</div>
				<button type='submit' className='w-full bg-blue-600 text-white rounded-sm py-1'>
					ĐĂNG NHẬP
				</button>
			</form>
		</div>
	);
}
