import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from './components/Header.jsx';
import Calculator from './pages/Calculator.jsx';
import DashBoard from './pages/DashBoard.jsx';
import Dice from './pages/Dice.jsx';
import Login from './pages/Login.jsx';
import MusicPlayer from './pages/MusicPlayer.jsx';

function App() {
	const isLogin = useSelector(s => s.LoginSlice.isLogin);
	return (
		<div className='App flex flex-col h-screen'>
			<Header />
			<div className='flex flex-col justify-center items-center h-full mt-14'>
				<Switch>
					<Redirect from='/dashboard' to='/' />
					<Route path='/' component={DashBoard} exact />
					<Route path='/may-tinh' component={Calculator} exact />
					<Route path='/xuc-xac' component={Dice} exact />
					<Route path='/music-player' component={MusicPlayer} exact />
				</Switch>
			</div>
		</div>
	);
}

export default App;
