import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPlayerWinner, swapPlayer } from "../../features/DiceSlice.jsx";

export default function PlayerPanel() {
  const activePlayer = useSelector((s) => s.DiceSlice.activePlayer);
  const isWinner = useSelector((s) => s.DiceSlice.isWinner);
  const currentScore = useSelector((s) => s.DiceSlice.currentScore);
  const playerScore = useSelector((s) => s.DiceSlice.playerScore);

  const dispatch = useDispatch();

  useEffect(() => {
    if (playerScore[1] >= 100) {
      dispatch(setPlayerWinner(1));
      dispatch(swapPlayer(0));
    }
    if (playerScore[2] >= 100) {
      dispatch(setPlayerWinner(2));
      dispatch(swapPlayer(0));
    }
  }, [playerScore]);

  return (
    <>
      <div className={`player-panel ${activePlayer === 1 ? "active" : ""}`}>
        <div className={`player-name ${isWinner === 1 ? "winner" : ""}`}>
          {isWinner === 1 ? `Chiến thắng` : `Người chơi 1`}
        </div>
        <div className="player-score">{playerScore[1]}</div>
        <div className="player-current-box">
          <div className="player-current-label">Điểm hiện tại</div>
          <div className="player-current-score">{currentScore[1]}</div>
        </div>
      </div>
      <div className={`player-panel ${activePlayer === 2 ? "active" : ""}`}>
        <div className={`player-name ${isWinner === 2 ? "winner" : ""}`}>
          {isWinner === 2 ? `Chiến thắng` : `Người chơi 2`}
        </div>
        <div className="player-score">{playerScore[2]}</div>
        <div className="player-current-box">
          <div className="player-current-label">Điểm hiện tại</div>
          <div className="player-current-score">{currentScore[2]}</div>
        </div>
      </div>
    </>
  );
}
