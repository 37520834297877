import { useSelector } from "react-redux";

export default function NumberTrack() {
  const songs = useSelector((s) => s.MusicSlice.songs);
  const nowIndex = useSelector((s) => s.MusicSlice.nowIndex);
  return (
    <>
      <span className="song-location text-sm font-semibold w-2/5 text-center leading-6">
        {`${nowIndex + 1}/${songs.length}`}
      </span>
    </>
  );
}
