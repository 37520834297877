import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCurrentScore,
  addPlayerScore,
  resetCurrenScore,
  resetScore,
  rollDice,
  swapPlayer,
  toggleShowRulePanel,
} from "../../features/DiceSlice.jsx";

export default function DiceController() {
  const activePlayer = useSelector((s) => s.DiceSlice.activePlayer);
  const dispatch = useDispatch();

  function handleNewGame() {
    dispatch(toggleShowRulePanel(true));
    dispatch(resetScore());
  }
  function handleRollDice() {
    dispatch(rollDice());
    dispatch(addCurrentScore());
  }
  function handleSwapPlayer() {
    dispatch(addPlayerScore());
    dispatch(resetCurrenScore());
    dispatch(swapPlayer(activePlayer === 0 ? 0 : activePlayer === 1 ? 2 : 1));
  }
  return (
    <>
      <button className="control btn-new" onClick={() => handleNewGame()}>
        Game mới
      </button>
      <button className="control btn-roll" onClick={() => handleRollDice()}>
        Tung xúc xắc
      </button>
      <button className="control btn-hold" onClick={() => handleSwapPlayer()}>
        Chuyển lượt
      </button>
    </>
  );
}
