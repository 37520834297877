import { createSlice } from '@reduxjs/toolkit';
import { evaluate } from 'mathjs';

const initialState = {
	expression: '',
	result: '',
	history: [],
	count: 0,
};
const calResult = createSlice({
	name: 'calculate',
	initialState,
	reducers: {
		addChar: function (s, action) {
			if (s.count < 1 && s.result) {
				s.expression = s.result + action.payload;
				s.count++;
			} else {
				s.expression = s.expression + action.payload;
			}
		},
		// Khi click vào history thì ghi đè lại biểu thức đang nhập
		replaceExpression: function (s, action) {
			s.expression = s.history[action.payload]?.result;
			s.result = null;
		},
		removeChar: function (s) {
			if (s.expression.length === 1) {
				s.expression = '';
				s.result = '';
			}
			s.expression = s.expression.slice(0, -1);
		},
		clearResult: function (s) {
			s.expression = '';
			s.result = '';
		},
		calculate: function (s) {
			const expression = s.expression.replace(/%/g, '/100');
			try {
				s.result = evaluate(expression).toString();
			} catch (error) {
				alert(`Phép tính bị lỗi, kiểm tra lại`);
				s.expression = '';
				return;
			}
			const history = { expression: s.expression, result: s.result };
			s.history.unshift(history);
			if (s.history.length > 4) {
				s.history.pop();
			}
			s.count = 0;
			// s.expression = s.result;
		},
	},
});

export const {
	addChar,
	removeChar,
	clearResult,
	calculate,
	replaceExpression,
} = calResult.actions;
export default calResult.reducer;
