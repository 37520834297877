import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCurrenScore, swapPlayer } from "../../features/DiceSlice.jsx";

export default function DicePanel(props) {
  const dice1 = useSelector((s) => s.DiceSlice.dice1);
  const dice2 = useSelector((s) => s.DiceSlice.dice2);
  const activePlayer = useSelector((s) => s.DiceSlice.activePlayer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (dice1 === 1 || dice2 === 1) {
      alert(`Bạn vừa đổ vào mặt 1 chấm, lượt chơi chuyển sang người tiếp theo`);
      setTimeout(() => {
        dispatch(resetCurrenScore());
        dispatch(
          swapPlayer(activePlayer === 0 ? 0 : activePlayer === 1 ? 2 : 1)
        );
      }, 300);
    }
  }, [dice1, dice2]);
  return (
    <>
      <div className="flex">
        <div id="dice-1" className="dice">
          <div className={`spinner dice-${dice1}`}>
            <div className="face1"></div>
            <div className="face2"></div>
            <div className="face3"></div>
            <div className="face4"></div>
            <div className="face5"></div>
            <div className="face6"></div>
          </div>
        </div>
        <div id="dice-2" className="dice">
          <div className={`spinner dice-${dice2}`}>
            <div className="face1"></div>
            <div className="face2"></div>
            <div className="face3"></div>
            <div className="face4"></div>
            <div className="face5"></div>
            <div className="face6"></div>
          </div>
        </div>
      </div>
    </>
  );
}
