import { Howler } from "howler";
import { useEffect, useRef, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoIosTimer } from "react-icons/io";
import {
  RiMusicFill,
  RiRepeatFill,
  RiRepeatOneFill,
  RiShuffleLine,
  RiVolumeMuteFill,
  RiVolumeUpFill,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIsLoop,
  changeTimer,
  toggleShuffer,
} from "../../features/MusicSlice.jsx";
import NumberTrack from "./NumberTrack.jsx";

export default function FunctionPanel() {
  const dispatch = useDispatch();
  const isLoop = useSelector((s) => s.MusicSlice.isLoop);
  const isShuffer = useSelector((s) => s.MusicSlice.isShuffer);
  const timer = useSelector((s) => s.MusicSlice.timer);

  const [showVolume, setShowVolume] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const refVolume = useRef();
  const refTooltip = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      refTooltip.current.classList.add("hidden");
    }, 1500);
    return () => {
      clearInterval(timeout);
    };
  }, [timer]);

  useEffect(() => {
    refVolume.current.value = Howler._volume * 100;
  }, []);

  function handleVolume(e) {
    Howler.volume(e.target.value / 100);
    if (Howler._volume === 0) {
      setIsMute(true);
    } else {
      setIsMute(false);
    }
  }
  function loop() {
    if (isLoop === "playNext") {
      return dispatch(changeIsLoop("loopOnce"));
    }
    if (isLoop === "loopOnce") {
      return dispatch(changeIsLoop("playOnce"));
    }
    if (isLoop === "playOnce") {
      return dispatch(changeIsLoop("playNext"));
    }
  }
  function handleTimer() {
    switch (timer) {
      case 0:
        dispatch(changeTimer(10));
        refTooltip.current.classList.remove("hidden");
        break;
      case 10:
        dispatch(changeTimer(20));
        refTooltip.current.classList.remove("hidden");
        break;
      case 20:
        dispatch(changeTimer(30));
        refTooltip.current.classList.remove("hidden");
        break;
      case 30:
        dispatch(changeTimer(60));
        refTooltip.current.classList.remove("hidden");
        break;
      case 60:
        dispatch(changeTimer(0));
        refTooltip.current.classList.remove("hidden");
        break;
    }
  }
  return (
    <>
      {/* Mute, countDown, Loop, Shuffer */}
      <div className="optinal-controller flex justify-between px-3">
        <div
          className="mute bg-gray-800 rounded-xl relative cursor-pointer"
          onClick={() => setShowVolume(!showVolume)}
        >
          <button>{isMute ? <RiVolumeMuteFill /> : <RiVolumeUpFill />}</button>
          <div
            className={`volume-bar bg-gray-600 absolute rounded-md ${
              showVolume ? "flex" : "hidden"
            }`}
            onMouseLeave={() => setShowVolume(false)}
          >
            <input
              type="range"
              name="volume"
              id="volume"
              min="0"
              max="100"
              className="m-auto"
              ref={refVolume}
              onInput={(e) => handleVolume(e)}
            />
          </div>
        </div>
        <div
          className="timer-shutdown bg-gray-800 rounded-xl relative"
          onClick={handleTimer}
        >
          <button>
            <IoIosTimer />
          </button>
          <div
            className={`tooltip h-8 bg-gray-800 absolute w-52 px-4 py-1 rounded-md hidden text-center leading-none`}
            ref={refTooltip}
          >
            {timer === 0
              ? `Bỏ hẹn giờ`
              : timer !== 0
              ? `Hẹn giờ tắt sau ${timer} phút`
              : "null"}
          </div>
        </div>

        <NumberTrack />
        <button className="repeat-song bg-gray-800 rounded-xl" onClick={loop}>
          {isLoop === "playNext" ? (
            <RiRepeatFill />
          ) : isLoop === "loopOnce" ? (
            <RiRepeatOneFill />
          ) : isLoop === "playOnce" ? (
            <RiMusicFill />
          ) : null}
        </button>
        <button
          className="shuffer-song bg-gray-800 rounded-xl"
          onClick={() => dispatch(toggleShuffer())}
        >
          {isShuffer ? <RiShuffleLine /> : <FaLongArrowAltRight />}
        </button>
      </div>
    </>
  );
}
