import { useSelector } from "react-redux";

SongTittle.propTypes = {};
SongTittle.defaultProps = {};

export default function SongTittle() {
  // console.log("SongTittle");
  const { songs, nowIndex } = useSelector((s) => s.MusicSlice);

  return (
    <div className="title h-16 text-sm px-3 flex flex-col ">
      <p className="font-semibold text-lg overflow-hidden overflow-ellipsis whitespace-nowrap">
        {songs[nowIndex]?.title}
      </p>
      <p>{songs[nowIndex]?.singer}</p>
    </div>
  );
}
