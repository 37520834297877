import { RiPercentLine } from "react-icons/ri";
import {
  FaEquals,
  FaDivide,
  FaTimes,
  FaMinus,
  FaPlus,
  FaBackspace,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  addChar,
  removeChar,
  clearResult,
  calculate,
} from "../../features/CalculatorSlice.jsx";

export default function ButtonArea() {
  const dispatch = useDispatch();

  return (
    <div className="buttonArea text-2xl h-96 bg-black grid grid-cols-4 grid-rows-5 ">
      <button className="btn-operator" onClick={() => dispatch(clearResult())}>
        C
      </button>
      <button className="btn-operator" onClick={() => dispatch(removeChar())}>
        <FaBackspace className="m-auto" />
      </button>
      <button className="btn-operator" onClick={() => dispatch(addChar("%"))}>
        <RiPercentLine className="m-auto" />
      </button>
      <button className="btn-operator" onClick={() => dispatch(addChar("/"))}>
        <FaDivide className="m-auto" />
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(7))}>
        7
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(8))}>
        8
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(9))}>
        9
      </button>
      <button className="btn-operator" onClick={() => dispatch(addChar("*"))}>
        <FaTimes className="m-auto" />
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(4))}>
        4
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(5))}>
        5
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(6))}>
        6
      </button>
      <button className="btn-operator" onClick={() => dispatch(addChar("-"))}>
        <FaMinus className="m-auto" />
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(1))}>
        1
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(2))}>
        2
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(3))}>
        3
      </button>
      <button className="btn-operator" onClick={() => dispatch(addChar("+"))}>
        <FaPlus className="m-auto" />
      </button>
      <button
        className="btn-calculator"
        onClick={() => dispatch(addChar("000"))}
      >
        000
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar(0))}>
        0
      </button>
      <button className="btn-calculator" onClick={() => dispatch(addChar("."))}>
        .
      </button>
      <button
        className="text-white focus:outline-none transform hover:scale-110"
        onClick={() => dispatch(calculate())}
      >
        <div className="rounded-full bg-yellow-600 w-14 h-14 m-auto flex">
          <FaEquals className="m-auto" />
        </div>
      </button>
    </div>
  );
}
