import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { replaceExpression } from "../../features/CalculatorSlice.jsx";

export default function ResultHistoryArea() {
  const history = useSelector((state) => state.calResult.history);
  const dispatch = useDispatch();

  return (
    <div className="h-3/4 text-xl">
      <div
        className="row1 mr-4 text-right h-1/3 text-gray-500 flex flex-col cursor-pointer "
        onClick={() => dispatch(replaceExpression(3))}
      >
        <p className="ml-auto">{history[3]?.expression}</p>
        <p className="ml-auto">
          {history[3]?.result ? `= ${history[3]?.result}` : ""}
        </p>
      </div>
      <div
        className="row2 mr-4 text-right h-1/3 text-gray-500 flex flex-col cursor-pointer"
        onClick={() => dispatch(replaceExpression(2))}
      >
        <p className="ml-auto">{history[2]?.expression}</p>
        <p className="ml-auto">
          {history[2]?.result ? `= ${history[2]?.result}` : ""}
        </p>
      </div>
      <div
        className="row3 mr-4 text-right h-1/3 text-gray-500 flex flex-col cursor-pointer"
        onClick={() => dispatch(replaceExpression(1))}
      >
        <p className="ml-auto">{history[1]?.expression}</p>
        <p className="ml-auto">
          {history[1]?.result ? `= ${history[1]?.result}` : ""}
        </p>
      </div>
    </div>
  );
}
